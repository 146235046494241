<template>
  <div class="be-venue-vouchers">
    <h4 class="be-h4 fw-bold">{{ $t('b2c_vouchers') }}</h4>
    <div v-if="vouchers.length > 0"  class="be-venue-vouchers__list">
      <template v-for="el in vouchers">
        <be-card-list-voucher
            :key="el.id"
            :data="el"
            :is-discount="vouchersHasDiscount"
            @select="selectVoucher"
        />
      </template>
    </div>
    <be-placeholder-content
      v-else
      :title="$t('b2c_no_vouchers_available')"
    />
  </div>
</template>

<script>
import BeCardListVoucher from "../../../components/cards/BeCardListVoucher.vue";
import BePlaceholderContent from "../../../components/common/BePlaceholderContent.vue";
export default {
  name: "BeVenueServices",
  components: {BeCardListVoucher, BePlaceholderContent},
  props: {
    vouchers: {
      type: Array
    }
  },
  computed: {
    vouchersHasDiscount() {
      return this.$store.getters.vouchersHasDiscount
    }
  },
  methods: {
    selectVoucher(id) {
      this.$store.commit('setFieldVoucherForm', {name: 'id', value: id})
      this.$router.push({ name: 'VenueVoucherDetails' })
    }
  }
}
</script>

<style scoped lang="scss">

.be-venue-vouchers {
  padding-top: 12px;
  padding-bottom: 28px;

  &__list {
    margin-left: -15px;
    margin-right: -15px;
  }
}

</style>

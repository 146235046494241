import {i18n} from "@/i18n";

export const langSuffix = (lang) => {
    const languages = {
        'en': 'en-us',
        'fr': 'fr-fr',
        'it': 'it-it',
        'es': 'es-es',
        'pl': 'pl',
        'de': 'de',
    }

    return languages[lang] ? languages[lang] : 'en-us'
};

export const authToken = () => {
    return localStorage.getItem("access_token");
};

export const langType = () => {
    return localStorage.getItem("lang_type");
};

export const removeAuthData = () => {
    localStorage.removeItem("returning_client");
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("customer");
};

export const imagePath= (url) => {
    const baseUrl = process.env.VUE_APP_ASSET_URL;
    return `${baseUrl}${url}`;
};


export const priceFormatter = (price, settings) => {
    price = Number(price).toFixed(settings.priceDecimalPlaces);
    if (settings.decimalSeparator === "comma") {
        price = price.replace(".", ",");
    }
    if (settings.currencySymbolPosition === "before") {
        return settings.currencySymbol + " " + price;
    }
    return price + " " + settings.currencySymbol;
};

export const timeFormatter = (num) => {
    let hours = Math.floor(num / 60);
    let minutes = Math.round((num / 60 - hours) * 60);

    if (hours === 0) {
        return `${minutes} ${i18n.t("b2c_minute_short")}`;
    } else if (minutes === 0) {
        return `${hours} ${i18n.t("b2c_hour_short")}`;
    } else {
        return `${hours} ${i18n.t("b2c_hour_short")} ${minutes} ${i18n.t(
          "b2c_minute_short"
        )}`;
    }
};

export const openBrowser = (url) => {
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.setAttribute('href', url)
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)
}

export const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const downloadFile = (url, fileName) => {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
};

export const copyToClipboard = (text) => {
    return new Promise((resolve, reject) => {
        navigator.clipboard.writeText(text).then(() => {
            resolve('Text copied to clipboard');
        }).catch(err => {
            reject('Failed to copy text: ', err);
        });
    })
};


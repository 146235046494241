import venue from "@/api/venue";
import venues from "@/api/venues";
import {VOUCHER_FORM} from "@/constants";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {Capacitor} from "@capacitor/core";
import {blobToBase64, downloadFile} from "@/utils/common";
import {FileOpener} from "@capacitor-community/file-opener";

export default {
  state: {
    venue: null,
    venueInfo: null,
    reviews: null,
    filters: {
      serviceStatus: false,
      employeeStatus: false,
      services: [],
      employees: []
    },
    services: [],
    reserveServices: [],
    employees: [],
    employeesType: [],
    installedEmployee: null,
    isArchivePackage: false,
    basket: [],
    savedBasket: [],
    purchaseOption: 'appointment', // appointment | voucher
    bookingNote: '',
    deposit: 0,
    vouchersState: null,
    voucherForm: {...VOUCHER_FORM},
    purchasedVoucher: null
  },
  getters: {
    venueId: (state) => {
      return state.venue.id
    },
    venue: (state) => {
      return state.venue
    },
    venueInfo: (state) => {
      return state.venueInfo
    },
    venueReviews: (state) => {
      return state.reviews
    },
    venueServices: (state) => {
      return state.services
    },
    venueEmployees: (state) => {
      return state.employees
    },
    installedEmployee: (state) => {
      return state.installedEmployee
    },
    basket: (state) => {
      return state.basket
    },
    basketPackages: (state) => {
      const packages = state.basket.filter(i => i.package_id)
      const packagesId = [...new Set(packages.map(i => i.package_id))];
      const formedPackages = packagesId.map(packageId => {
        return {
          id: packageId,
          title: packages.filter(i => i.package_id === packageId)[0].package_title,
          total_price: packages.filter(i => i.package_id === packageId)[0].total_price,
          total_duration: packages.filter(i => i.package_id === packageId)[0].total_duration,
          services: [...packages.filter(i => i.package_id === packageId)]
        }
      })
      return formedPackages;
    },
    savedBasket: (state) => {
      return state.savedBasket
    },
    bookingNote: (state) => {
      return state.bookingNote
    },
    isArchivePackage: (state) => {
      return state.isArchivePackage
    },

    servicesFilters: (state) => {
      return state.filters.services
    },
    employeesFilters: (state) => {
      return state.filters.employees
    },
    filterStatus: (state) => {
      return {
        services: state.filters.serviceStatus,
        employees: state.filters.employeeStatus,
      }
    },

    priceData: (state) => {
      return {
        priceDecimalPlaces: state.venueInfo ? state.venueInfo.price_decimal_places : '',
        decimalSeparator: state.venueInfo ? state.venueInfo.decimal_separator : '',
        currencySymbol: state.venueInfo ? state.venueInfo.currency_symbol : '',
        currencySymbolPosition: state.venueInfo ? state.venueInfo.currency_symbol_position : ''
      };
    },

    purchaseOption: (state) => {
      return state.purchaseOption
    },

    venuePaymentVariant: (state) => {
      return state.venueInfo ? state.venueInfo.payment_variant : '';
    },
    isPaymentsEnabled: (state) => {
      return state.venueInfo && state.venueInfo.payments_enabled;
    },

    venueDeposit: (state) => {
      return state.deposit;
    },

    isVoucherEnabled: (state) => {
      const venue = state.venueInfo

      return !!venue?.payments_enabled  && !!venue?.areas && !!venue?.areas.vouchers && !!venue?.vouchers_enabled  && !!venue?.vouchers_sell_online;
    },

    voucherForm: (state) => {
      return state.voucherForm
    },

    voucherExpiration: (state) => {
      return state.vouchersState?.vouchers_expiration
    },

    vouchersHasDiscount: (state) => {
      const voucher = state.vouchersState

      return !!voucher?.vouchers_enable_discounts;
    },

    venueVouchers: (state) => {
      return state.vouchersState ? state.vouchersState.vouchers_options : []
    },

    venueVouchersPolicy: (state) => {
      return state.vouchersState ? state.vouchersState.vouchers_policy : ''
    },

    canVoucherPurchase: (state) => {
      return !!state.voucherForm && !!state.voucherForm.id && !!state.voucherForm.type
    },
    purchasedVoucher: (state) => {
      return state.purchasedVoucher
    }
  },
  mutations: {
    setVenue: (state, payload) => {
      state.venue = payload
    },
    setVenueInfo: (state, payload) => {
      state.venueInfo = payload
    },
    setVenueReview: (state, payload) => {
      state.reviews = {
        data: payload.current_page > 1 ? [...state.reviews.data, ...payload.data] : payload.data,
        currentPage: payload.current_page,
        lastPage: payload.last_page,
        total: payload.total
      }
    },
    setVenueServices: (state, payload) => {
      let filters = payload.map((item, index) => {
        return {
          id: `${item.group_title.toLowerCase().replace(/[^A-Z0-9]/ig, "_")}-${index + 1}`,
          title: item.group_title,
          checked: false
        }
      })
      filters.shift()

      const services = payload.map((item, index) => {
        if(item.is_package) {
          return {
            ...item,
            packages: item.packages.map(i => ({...i, selected: false})),
            services: item.services,
            isShow: true,
            idCollapse: `${item.group_title.toLowerCase().replace(/[^A-Z0-9]/ig, "_package_")}-${index + 1}`
          };
        }
        return {
          ...item,
          services: item.services.map((k) => {
            return {
              ...k,
              isShowVariants: false
            };
          }),
          isShow: true,
          idCollapse: `${item.group_title.toLowerCase().replace(/[^A-Z0-9]/ig, "_")}-${index + 1}`
        };
      });

      state.services = [...services]
      state.reserveServices = [...services]
      state.filters.services = [...filters]
    },
    setVenueEmployees: (state, payload) => {
      state.employees = payload
    },
    updateVenueEmployeesType: (state, payload) => {
      state.employeesType = payload
      state.employees = state.employees.map(i => {
        return {
          ...i,
          position: payload.find(k => k.id === i.employee_type_id) ? payload.find(k => k.id === i.employee_type_id).title : ''
        }
      })
      state.filters.employees = state.employees.map(i => {
        return {
          ...i,
          checked: false,
          position: payload.find(k => k.id === i.employee_type_id) ? payload.find(k => k.id === i.employee_type_id).title : ''
        }
      })
    },

    selectService: (state, payload) => {
      const types = state.employeesType
      const service = state.services
        .filter(item => !item.is_package && item.services
          .find(i => i.id === payload.service_id))[0].services
            .find(i => i.id === payload.service_id)

      const variant = service.variants.find(i => i.id === payload.variant_id)

      const obj = {
        employee_id: state.installedEmployee ? state.installedEmployee.id : null,
        employee_name: state.installedEmployee ? `${state.installedEmployee.first_name} ${state.installedEmployee.last_name}` : '',
        employee_position: state.installedEmployee ? state.installedEmployee.position : '',
        service_id: payload.service_id,
        variant_id: payload.variant_id,
        service_title: service.title,
        group_title: service.group_title,
        type: service.booking,
        variant_title: variant.caption,
        price: variant.price,
        price_formatted: variant.price_formatted,
        duration: variant.duration,
        employees: service.employees.map(i => {
          return {
            ...i,
            position: types.find(k => k.id === i.employee_type) ?
              types.find(k => k.id === i.employee_type).title : ''
          }
        })
      };

      state.basket.push(obj)

      state.services = state.services.map(item => {
        return {
          ...item,
          services: item.is_package ? item.services : item.services.map(s => {
            return {
              ...s,
              variants: s.variants.map(v => {
                if(v.id === payload.variant_id) {
                  return {
                    ...v,
                    selected: true
                  }
                }
                return {...v}
              })
            }
          })
        }
      })
    },
    removeService: (state, id) => {
      let packages = state.basket.filter(i => i.package_id)
      let services = state.basket.filter(i => !i.package_id)
      state.basket = [...packages, ...services.filter(i => i.variant_id !== id)]

      state.services = state.services.map(item => {
        return {
          ...item,
          services: item.is_package ? item.services : item.services.map(s => {
            return {
              ...s,
              variants: s.variants.map(v => {
                if(v.id === id) {
                  return {
                    ...v,
                    selected: false
                  }
                }
                return {...v}
              })
            }
          })
        }
      })
    },
    selectPackage: (state, payload) => {
      const types = state.employeesType

      let packages = state.services.find(i => i.is_package) ? state.services.find(i => i.is_package).packages : []
      let packageItem = packages.find(i => i.id === payload.package_id)

      if(packageItem) {
        packageItem.services.map(service => {
          const variant = service.variants[0]

          const obj = {
            employee_id: state.installedEmployee ? state.installedEmployee.id : null,
            employee_name: state.installedEmployee ? `${state.installedEmployee.first_name} ${state.installedEmployee.last_name}` : '',
            employee_position: state.installedEmployee ? state.installedEmployee.position : '',
            package_id: packageItem.id,
            package_title: packageItem.title,
            total_price: packageItem.total,
            total_duration: packageItem.total_duration,
            service_id: service.id,
            variant_id: variant.id,
            service_title: service.title,
            group_title: service.group_title,
            type: service.booking,
            variant_title: variant.caption,
            custom_price: variant.custom_price,
            price: variant.price,
            price_formatted: variant.price_formatted,
            custom_duration: variant.custom_duration,
            duration: variant.duration,
            employees: service.employees.map(i => {
              return {
                ...i,
                position: types.find(k => k.id === i.employee_type) ?
                  types.find(k => k.id === i.employee_type).title : ''
              }
            })
          };

          state.basket.push(obj)
        })

        state.services = state.services.map(i => {
          if(i.is_package) {
            return {
              ...i,
              packages: i.packages.map(k => ({...k, selected: payload.package_id === k.id ? true : k.selected}))
            }
          }
          return {...i}
        })
      } else {
        state.isArchivePackage = true
      }
    },
    removePackage: (state, payload) => {
      state.basket = state.basket.filter(i => i.package_id !== payload.id)
      state.services = state.services.map(i => {
        if(i.is_package) {
          return {
            ...i,
            packages: i.packages.map(k => ({...k, selected: payload.id === k.id ? false : k.selected}))
          }
        }
        return {...i}
      })
    },
    updateBasket: (state, payload) => {
      state.basket = payload
    },

    setEmployeeToBook: (state, payload) => {
      state.installedEmployee = payload ? state.employees.find(i => i.id === payload.id) : payload
    },
    selectEmployee: (state, payload) => {
      state.basket = state.basket.map(item => {
        if(payload.variant_id === item.variant_id) {
          return {
            ...item,
            employee_id: payload.employee_id,
            employee_name: payload.employee_name,
            employee_position: payload.employee_position
          }
        }
        return item
      })
    },

    setTimeToBasket: (state, payload) => {
      state.basket = state.basket.map(i => {
        return {
          ...i,
          time: payload.services.find(k => k.variant_id === i.variant_id).time,
          date: payload.date
        }
      })
    },
    setSavedBasket: (state, payload) => {
      const basket = payload.map(i => {
        return {
          ...state.basket.find(k => k.variant_id === i.variant_id) ?
            state.basket.find(k => k.variant_id === i.variant_id) : {},
          ...i,
          employee_name: `${state.employees.find(k => k.id === i.employee_id).first_name} ${state.employees.find(k => k.id === i.employee_id).last_name}`
        }
      })
      state.basket = basket
      state.savedBasket = basket
    },

    removeSavedSlots: (state, payload) => {
      state.savedBasket = state.savedBasket.filter(i => i.variant_id !== payload.variant_id)
    },
    removeSavedSlotsByPackage: (state, payload) => {
      state.savedBasket = state.savedBasket.filter(i => i.package_id !== payload.id)
    },

    cleanBasket: (state, value) => {
      if(value) {
        state.basket = []
        state.savedBasket = []
        state.bookingNote = ''
        state.installedEmployee = null
        state.services = state.services.map(item => {
          if(item.is_package) {
            return {
              ...item,
              packages: item.packages.map(i => ({...i, selected: false}))
            }
          }
          return {
            ...item,
            services: item.services.map(s => {
              return {
                ...s,
                variants: s.variants.map(v => {
                  return {
                    ...v,
                    selected: false
                  }
                })
              }
            })
          }
        })
      }
    },

    setPurchaseOption: (state, payload) => {
      state.purchaseOption = payload
    },
    setBookingNote: (state, payload) => {
      state.bookingNote = payload.note
    },

    filterByServices: (state, payload) => {
      state.filters.services = state.filters.services.map(i => {
        return {
          ...i,
          checked: i.title === payload.value ? payload.checked : i.checked
        }
      })
    },
    filterByEmployees: (state, payload) => {
      state.filters.employees = state.filters.employees.map(i => {
        return {
          ...i,
          checked: i.id === payload.value ? payload.checked : i.checked
        }
      })
    },

    setVenueDeposit: (state, payload) => {
      state.deposit = payload.deposit;
    },

    setVouchersState: (state, payload) => {
      state.vouchersState = payload;
    },
    resetVoucherForm: (state) => {
      state.voucherForm = {...VOUCHER_FORM}
    },
    resetDetailVoucherForm (state) {
      state.voucherForm = {
        ...VOUCHER_FORM,
        id: state.voucherForm.id,
        type: state.voucherForm.type,
      };
    },
    setFieldVoucherForm: (state, payload) => {
      state.voucherForm[payload.name] = payload.value
    },
    buildVoucherForm: (state, payload) => {
      state.voucherForm = {...state.voucherForm, ...payload}
    },
    setPurchasedVoucher: (state, payload) => {
      state.purchasedVoucher = payload
    },

    applyFilter: (state) => {
      let reserve = state.reserveServices
      let serviceFilters = state.filters.services.filter(i => i.checked)
      let employeesFilters = state.filters.employees.filter(i => i.checked)
      let popularServices = {...state.reserveServices[0]}
      let packages = {...state.reserveServices.find(i => i.is_package)}
      let filteredServices = []

      // Reset Status
      state.filters.serviceStatus = false
      state.filters.employeeStatus = false

      if(employeesFilters.length > 0) {
        reserve = reserve.map(i => {
          if(!i.is_package) {
            return {
              ...i,
              services: i.services.filter(k =>
                k.employees.filter(r => employeesFilters.findIndex(e => e.id === r.id) > -1).length !== 0
              )
            }
          } else {
            return {
              ...i,
              packages: i.packages.filter(p => p.services.filter(k =>
                k.employees.filter(r => employeesFilters.findIndex(e => e.id === r.id) > -1).length !== 0).length > 0
              )
            }
          }
        })
        popularServices.services = popularServices.services.filter(i =>
          i.employees.filter(r => employeesFilters.findIndex(e => e.id === r.id) > -1).length !== 0)

        if(packages.packages) {
          packages.packages = packages.packages.map(p => {
            return {
              ...p,
              services: p.services ? p.services.filter(i =>
                i.employees.filter(r => employeesFilters.findIndex(e => e.id === r.id) > -1).length !== 0) : []
            }
          })
        }

        // Set status
        state.filters.employeeStatus = true
      }

      filteredServices = serviceFilters.length > 0
        ? reserve.filter(i => serviceFilters.findIndex(k => k.title === i.group_title) > -1)
        : reserve

      if(serviceFilters.length > 0) {
        popularServices.services = popularServices.services.filter(i => serviceFilters.findIndex(k => k.title === i.group_title) > -1)

        if(popularServices.services.length > 0) {
          filteredServices = [popularServices, packages, ...filteredServices]
        }

        // Set status
        state.filters.serviceStatus = true
      }

      state.services = filteredServices.map(i => {
        if(!i.is_package && i.services.length > 0) {
          return {
            ...i
          }
        }
        return {
          ...i
        }
      }).filter(i => i)
    },
    cleanServiceFilters: (state) => state.filters.services = state.filters.services.map(i => ({...i, checked: false})),
    cleanEmployeeFilters: (state) => state.filters.employees = state.filters.employees.map(i => ({...i, checked: false}))
  },
  actions: {
    // Get Venues profile action
    async getVenueProfile({commit}, id) {
      try {
        const response = await venues.venueProfile(id);
        commit('setVenue', response.data)
      } catch (err) {
        console.log(err.message)
      }
    },
    // Get Venues info action
    async getVenueInfo({commit}, id) {
      try {
        const response = await venue.venueInfo(id);
        commit('setVenueInfo', response.data)
      } catch (err) {
        console.log(err.message)
      }
    },
    // Get Venues info action
    async getVenueReviews({commit}, payload) {
      try {
        const response = await venue.getReviews(payload);
        commit('setVenueReview', response.data)
      } catch (err) {
        console.log(err.message)
      }
    },
    // Get Venues services action
    async getVenueServices({commit}, id) {
      try {
        const response = await venue.services(id);
        commit('setVenueServices', response.data)
      } catch (err) {
        console.log(err.message)
      }
    },
    // Get Venues vouchers action
    async getVenueVouchers({commit}, id) {
      try {
        const response = await venue.vouchers(id);
        commit('setVouchersState', response.data.data)
      } catch (err) {
        console.log(err.message)
      }
    },
    // Get Venues employees action
    async getVenueEmployees({commit, dispatch}, id) {
      try {
        const response = await venue.employees(id);
        commit('setVenueEmployees', response.data)
        await dispatch('getVenueTypesEmployee', id)
      } catch (err) {
        console.log(err.message)
      }
    },
    // Get Venues types employees action
    async getVenueTypesEmployee({commit}, id) {
      try {
        const response = await venue.typesEmployee(id);
        commit('updateVenueEmployeesType', response.data)
      } catch (err) {
        console.log(err.message)
      }
    },
    // Voucher purchase
    voucherPurchase({commit, getters}, id) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if(!getters.getPaymentMethod) {
          reject('Payment method not found')
        }

        const form = {
          ...getters.voucherForm,
          payment_id: getters.getPaymentMethod.id // Payment method ID
        }

        if(form.send === 'instantly') {
          delete form.date_send;
        }

        try {
          const response = await venue.buyVoucher({
            id: id,
            data: form
          });

          if(!!response.data.voucher && !response.data.data) {
            commit('setPurchasedVoucher', response.data.voucher);
          } else {
            resolve(response.data.data);
          }

          resolve('ok');
        } catch (e) {
          reject(e);
        }
      });
    },
    // Voucher check purchase
    checkVoucherPurchase({commit}, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await venue.voucherCheckPayment({
            id: payload.id,
            paymentIntentId: payload.paymentIntentId
          });

          if(response.data.voucher) {
            commit('setPurchasedVoucher', response.data.voucher);
            resolve('success');
          } else {
            reject('error');
          }

        } catch (e) {
          reject(e);
        }
      });
    },
    // Voucher download PDF
    downloadVoucher(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await venue.downloadVoucher({
            id: payload.id,
            voucherId: payload.voucherId
          });

          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          const fileName = `gift-card-${payload.code}.pdf`

          if(Capacitor.platform !== 'web') {
            const base64Data = await blobToBase64(response.data);
            const hasPermissions = await Filesystem.checkPermissions()

            if(hasPermissions.publicStorage === 'granted') {
              const savedFile = await Filesystem.writeFile({
                path: `${fileName}`,
                data: base64Data,
                directory: Directory.Documents,
                recursive: true
              });
              await FileOpener.open({
                filePath: savedFile.uri,
                contentType: 'application/pdf',
              });
            } else {
              const status = await Filesystem.requestPermissions()

              if(status.publicStorage === 'granted') {
                const savedFile = await Filesystem.writeFile({
                  path: `${fileName}`,
                  data: base64Data,
                  directory: Directory.Documents,
                  recursive: true
                });
                await FileOpener.open({
                  filePath: savedFile.uri,
                  contentType: 'application/pdf',
                });
              }
            }
          } else {
            downloadFile(url, fileName)
          }
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    // Remove slot action
    async removeSlot({commit}, payload) {
      if(payload.package_id) {
        await commit('removePackage', {id: payload.package_id})
        await commit('removeSavedSlotsByPackage', {id: payload.package_id})
        payload.services.forEach(i => {
          venue.removeSlot({id: payload.id, basket_id: i.basket_id, token: i.token})
        })
      } else {
        await commit('removeSavedSlots', payload)
        await commit('removeService', payload.variant_id)
        try {
          await venue.removeSlot({id: payload.id, basket_id: payload.basket_id, token: payload.token})
        } catch (e) {
          console.log(e)
        }
      }

    },

    // Reset filter
    async resetFilter({commit}, payload) {
      if(payload.type === 'services') {
        await commit('cleanServiceFilters')
      } else if(payload.type === 'employees') {
        await commit('cleanEmployeeFilters')
      }

      await commit('applyFilter')
    }
  }
}

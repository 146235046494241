<template>
  <main-wrapper>
    <div class="be-venue-basket">
      <div class="be-venue-basket__header">
        <be-basket-info is-timer/>
      </div>
      <div class="be-venue-basket__list">
        <be-wrapper-basket-package
            v-for="packageItem in basketPackages"
            :key="packageItem.id"
            :title="packageItem.title"
            :price="packageItem.total_price | priceFormat(priceData)"
            :duration="packageItem.total_duration | time"
            @remove="removeSlot({
              id: venueId,
              package_id: packageItem.id,
              basket_id: packageItem.basket_id,
              token: packageItem.token,
              variant_id: packageItem.variant_id,
              services: packageItem.services,
            })"
        >
          <be-card-list-basket
              v-for="item in packageItem.services"
              :key="item.variant_id"
              :title="item.service_title"
              :employee="item.employee_name"
              :price="item.custom_price | priceFormat(priceData)"
              :old-price="item.price_formatted"
              :time="item.time ? item.time : ''"
              :duration="item.custom_duration | time"
              hide-remove-button
          />
        </be-wrapper-basket-package>
        <be-card-list-basket
          v-for="item in basket.filter(i => !i.package_id)"
          :key="item.variant_id"
          :title="item.service_title"
          :employee="item.employee_name"
          :price="item.price_formatted"
          :time="item.time ? item.time : ''"
          :duration="item.duration | time"
          @remove="removeSlot({
            id: venueId,
            basket_id: item.basket_id,
            token: item.token,
            variant_id: item.variant_id,
          })"
        />
      </div>
      <div class="be-venue-basket__total">
        <be-link
            @click="handlerShowNote"
            :icon-left="note.length > 0 ? 'icon-be-edit' : 'icon-be-plus-round'"
            variant="primary">
          {{ note.length > 0 ? $t("b2c_edit_note") : $t("b2c_add_note") }}
        </be-link>

        <span v-if="priceData" class="total">
          {{ $t("b2c_total") }}: <strong>{{totalBasketPrice | totalBasket(basket, priceData)}}</strong>
        </span>
      </div>
      <div v-if="venuePaymentVariant === 'card'">
        <div v-if="!isLoadingPM">
          <div v-if="!paymentMethod" class="be-venue-basket__payment-method">
            <h5>{{$t('b2c_this_service_requires_adding_a_card')}}</h5>
            <p>{{$t('b2c_you_must_add_a_card_to_continue_booking')}}</p>
            <be-button @click="createPaymentIndent" variant="primary">{{$t('b2c_add_card')}}</be-button>
          </div>
          <be-payment-method
              v-else
              @create="createPaymentIndent"
          />
        </div>
        <div v-else class="text-center pt-5">
          <b-spinner class="be-spinner"/>
        </div>
      </div>
      <div
          v-if="
              isPaymentsEnabled &&
              (venuePaymentVariant === 'part_percent' ||
                venuePaymentVariant === 'part_value')
            "
          class="be-basket-comment"
      >
        <p class="be-basket-comment__title">{{ $t("b2c_this_service_requires_a_prepayment") }}</p>
        <p>
          {{ venueDeposit | priceFormat(priceData) }}
          {{ $t("b2c_will_be_charged_to_your_card_now") }}
        </p>
      </div>
    </div>

    <template #footer>
      <div v-if="isPaymentsEnabled && venuePaymentVariant !== 'card'">
        <div
            v-if="venuePaymentVariant === 'part_percent' || venuePaymentVariant === 'part_value'"
            class="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2">
          <div class="be-footer__controls-selected__info">
                  <span class="label">
                    {{ $t("b2c_deposit") }}
                  </span>
            <p class="price">
              {{ venueDeposit | priceFormat(priceData) }}
            </p>
          </div>
          <div class="be-footer__controls-selected__btn">
            <be-button
                variant="primary"
                @click="redirectToPaymentProcess('deposit')"
            >
              {{$t('b2c_pay_deposit')}}
            </be-button>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="be-footer__controls-selected__info">
                  <span class="label">
                    {{ $t("b2c_full_amount") }}
                  </span>
            <p class="price">
              {{ totalBasketPrice | totalBasket(basket, priceData) }}
            </p>
          </div>
          <div class="be-footer__controls-selected__btn">
            <be-button
                variant="primary"
                @click="redirectToPaymentProcess('full')"
            >
              {{$t('b2c_pay_full_amount')}}
            </be-button>
          </div>
        </div>
      </div>
      <div v-else>
        <be-button
            @click="booking"
            :is-loading="isLoadingBooking"
            variant="primary"
            :is-full="true"
            :disabled="venuePaymentVariant === 'card' && !paymentMethod"
        >
          {{ $t('b2c_book') }}
        </be-button>
      </div>
    </template>
    <be-modal
      :id="noteModalId"
      :title="noteModalTitle">
      <div class="be-form-group pb-2">
        <div class="d-flex justify-content-between">
          <label for="bookingNote" class="be-form-label">{{ $t('b2c_note') }}</label>
          <span class="length">{{reserveNote.length}}/500</span>
        </div>
        <textarea
            v-model="reserveNote"
            maxlength="500"
            class="be-form-control be-form-control-note"
            id="bookingNote"/>
      </div>
      <be-button @click="addNote" is-full variant="primary">{{noteModalTitle}}</be-button>
    </be-modal>
    <be-modal-prompts no-close-on-backdrop :id="idModalSavePaymentCard">
      <be-prompt
          :icon="iconWarning"
          :title="$t('b2c_save_my_credit_card_for_future_bookings')"
      />
      <template #buttons>
        <be-button @click="createPaymentMethod(false)" variant="error">{{$t('b2c_no')}}</be-button>
        <be-button @click="createPaymentMethod(true)" variant="primary">{{$t('b2c_yes')}}</be-button>
      </template>
    </be-modal-prompts>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeLink from "@/components/common/BeLink";
import BeCardListBasket from "@/components/cards/BeCardListBasket";
import BeBasketInfo from "@/components/common/BeBasketInfo";
import {priceFormatter, timeFormatter} from "@/utils/common";
import venue from "@/api/venue";
import BeModal from "@/components/BeModals/BeModal";
import BeButton from "@/components/common/BeButton";
import BeWrapperBasketPackage from "@/components/cards/BeWrapperBasketPackage";
import EventBus from "@/utils/event-bus";
import payment from "@/api/payment";
import BePaymentMethod from "@/views/Venues/_id/PaymentProcess/BePaymentMethod.vue";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts.vue";
import BePrompt from "@/components/common/BePrompt.vue";
import iconWarning from "@/assets/img/icons/icon-warning.svg";
import {PaymentSheetEventsEnum, Stripe} from "@capacitor-community/stripe";

export default {
  name: "VenueBasket",
  components: {
    BePrompt, BeModalPrompts,
    BePaymentMethod,
    BeWrapperBasketPackage, BeButton, BeModal, BeCardListBasket, MainWrapper, BeLink, BeBasketInfo},
  computed: {
    venueId() {
      return this.$store.getters.venueId
    },
    venueDeposit() {
      return this.$store.getters.venueDeposit
    },
    paymentMethod() {
      return this.$store.getters.getPaymentMethod;
    },
    basket() {
      return this.$store.getters.savedBasket
    },
    basketPackages() {
      return this.$store.getters.basketPackages
    },
    totalBasketPrice() {
      let total = 0
      this.$store.getters.savedBasket.forEach(i => {
        if (i.type !== 'hide_price') {
          if(i.package_id) {
            total = total + i.custom_price
          } else {
            total = total + i.price
          }
        }
      })
      return total
    },
    savedBasket() {
      return this.$store.getters.savedBasket.map(item => {
        return {
          basket_id: item.basket_id,
          employee_id: item.employee_id,
          service_id: item.service_id,
          variant_id: item.variant_id
        }
      })
    },
    bookingNote() {
      return this.$store.getters.bookingNote
    },
    priceData() {
      return this.$store.getters.priceData
    },
    note() {
      return this.$store.getters.bookingNote
    },
    venuePaymentVariant() {
      return this.$store.getters.venuePaymentVariant
    },
    isPaymentsEnabled() {
      return this.$store.getters.isPaymentsEnabled
    },
    confirmPhoneIdModal() {
      return this.$store.getters.confirmPhoneIdModal
    },
    isHasCustomerPhone() {
      const profile = this.$store.getters.userProfile

      return profile ? !profile.phone && window.navigator.onLine : false
    },
  },
  filters: {
    priceFormat(amount, formatData) {
      return priceFormatter(amount, formatData)
    },
    totalBasket(price, array, format) {
      const hidePrice = array.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      const bookable = array.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (hidePrice.length > 0 && bookable.length > 0) {
        return `from ${priceFormatter(price, format)}`;
      } else if (hidePrice.length === 0) {
        return priceFormatter(price, format);
      } else {
        return hidePrice[0].price_formatted;
      }
    },
    time(time) {
      return timeFormatter(time)
    }
  },
  data() {
    return {
      iconWarning,
      isLoadingPM: false,
      isLoadingBooking: false,
      idModalSavePaymentCard: 'Modal-Save-Payment-Card',
      noteModalId: 'add-note-modal',
      noteModalTitle: 'Add note',
      reserveNote: ''
    }
  },
  mounted() {
    if(this.basket.length === 0) {
      this.$router.push(`/venues/${this.$route.params.id}/services`)
      this.$store.commit('startTimer', false)
      return;
    }

    this.noteModalTitle = this.$t("b2c_add_note")
    this.checkCustomer(this.venueId)

    const self = this
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        if(payload.config.url.split('/')[1] === 'check-customer') {
          self.checkCustomer(self.venueId);
        }
      }
    });

    if(this.venuePaymentVariant === 'card') {
      this.checkPaymentMethod();
    }
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    loaderPM(value) {
      this.isLoadingPM = value;
    },
    loaderBooking(value) {
      this.isLoadingBooking = value;
    },
    handlerShowNote() {
      this.$bvModal.show(this.noteModalId)
      this.reserveNote = this.note ? this.note : this.reserveNote
      if(this.note.length > 0) {
        this.noteModalTitle = this.$t("b2c_edit_note")
      } else {
        this.noteModalTitle = this.$t("b2c_add_note")
      }
    },
    addNote() {
      this.$store.commit('setBookingNote', { note: this.reserveNote })
      this.$bvModal.hide(this.noteModalId)
    },
    redirectToPaymentProcess(variant) {
      this.$store.commit('setPaymentVariant', {variant});
      this.$store.commit('setPurchaseOption', 'appointment')
      this.$router.push(`/venues/${this.$route.params.id}/payment-process`);
    },
    async createPaymentIndent() {
      try {
        const {data} = await payment.getPaymentIndent(this.venueId);

        await Stripe.initialize({
          publishableKey: process.env.VUE_APP_STRIPE_API_PUBLISH_KEY,
          stripeAccount: data.account_id,
        });

        await Stripe.createPaymentSheet({
          setupIntentClientSecret: data.client_secret
        });

        const result = await Stripe.presentPaymentSheet();
        if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
          this.$bvModal.show(this.idModalSavePaymentCard);
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createPaymentMethod(value) {
      this.$bvModal.hide(this.idModalSavePaymentCard);
      this.loaderPM(true);
      try {
        const {data} = await payment.getPaymentMethod(this.venueId, value);
        if(data) {
          await this.$store.commit('setPaymentMethod', data);
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderPM(false);
      }
    },
    async checkPaymentMethod() {
      this.loaderPM(true);
      try {
        const {data} = await payment.getPaymentData(this.venueId);
        if(data.payment_method) {
          await this.$store.commit('setPaymentMethod', data.payment_method);
        }

      } catch (e) {
        console.log(e);
      } finally {
        this.loaderPM(false);
      }
    },
    async checkCustomer(id) {
      try {
        await venue.checkCustomer({id})
        await this.$router.push(`/venues/${this.$route.params.id}/basket`)
      } catch (e) {
        if(e.status === 403) {
          this.$store.commit('setFollowingPath', `/venues/${this.$route.params.id}/basket`);
          await this.$router.push(`/authorization`)
        }
      }
    },
    async removeSlot(payload) {
      await this.$store.dispatch('removeSlot', payload)
    },
    async booking() {
      if(!this.isHasCustomerPhone) {
        this.loaderBooking(true);
        const data = {
          id: this.venueId,
          note: this.bookingNote,
          services: [...this.savedBasket]
        }
        try {
          await venue.saveAppointment(data)

          this.isLoadingBook = false
          await this.$router.push(`/venues/${this.$route.params.id}/created`)
          await this.$store.commit('startTimer', false)
        } catch (e) {
          console.log(e)
        } finally {
          this.loaderBooking(false);
        }
      } else {
        this.$bvModal.show(this.confirmPhoneIdModal)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-basket {
  &__list {
    padding-bottom: 18px;
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total {
      font-size: 16px;
      font-weight: normal;
      color: $text-color-light;

      strong {
        font-weight: 600;
        color: $text-color;
      }
    }
  }
  &__payment-method {
    padding-top: 45px;
    text-align: center;

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 10px;
    }

    p {
      font-size: 13px;
      color: $text-color-light;
      margin-bottom: 20px;
    }
  }
}

.be-basket-comment {
  text-align: center;
  padding-top: 25px;

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  p {
    font-size: 13px;
  }
}

</style>

import { render, staticRenderFns } from "./BeCardListVoucher.vue?vue&type=template&id=36ea49fe&scoped=true"
import script from "./BeCardListVoucher.vue?vue&type=script&lang=js"
export * from "./BeCardListVoucher.vue?vue&type=script&lang=js"
import style0 from "./BeCardListVoucher.vue?vue&type=style&index=0&id=36ea49fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ea49fe",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],ref:"mainButton",staticClass:"be-btn",class:`
    ${_vm.flat ? _vm.variantsFlat[_vm.variant] : _vm.isOutline ? _vm.variantsOutline[_vm.variant] : _vm.variants[_vm.variant]}
    ${_vm.disabled ? 'be-btn-disabled' : ''}
    ${_vm.isRubble ? 'be-rubble' : ''}
    ${_vm.isLoading ? 'be-btn-loading be-btn-disable' : ''}
    ${_vm.isSelected ? 'be-btn-selected' : ''}
    ${_vm.round ? 'be-btn-round' : ''}
    ${_vm.square ? 'be-btn-square' : ''}
    ${_vm.badge ? 'be-btn-badge' : ''}
    ${_vm.isFull ? 'be-btn-full' : ''}`,attrs:{"type":_vm.type,"disabled":_vm.isLoading || _vm.disabled},on:{"click":_vm.handlerClick}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"be-btn__spinner",attrs:{"small":""}}):_vm._e(),_c('span',[_vm._t("default"),(_vm.iconRight)?_c('i',{staticClass:"be-btn__icon-right",class:_vm.iconRight}):_vm._e(),(_vm.isSelected)?_c('i',{staticClass:"icon-selected icon-be-check"}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="be-venue-payment-card">
    <div class="be-venue-payment-card__price">
      <template v-if="purchaseOption === 'appointment'">
        <be-basket-summary-string/>
      </template>
      <template v-if="purchaseOption === 'voucher'">
        <span>{{currentVoucher.price_formatted}}</span>
      </template>
    </div>
    <div class="be-venue-payment-card__sub">{{$t('b2c_amount_due')}}</div>
  </div>
</template>

<script>
import BeBasketSummaryString from "../../../../components/common/BeBasketSummaryString.vue";

export default {
  name: 'BePaymentSummary',
  components: {BeBasketSummaryString},
  computed: {
    purchaseOption() {
      return this.$store.getters.purchaseOption
    },
    voucherForm() {
      return this.$store.getters.voucherForm
    },
    currentVoucher() {
      const vouchers = this.$store.getters.venueVouchers;

      return {
        ...vouchers.find((v) => v.id === this.voucherForm.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-payment-card {
  background: $white-100;
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid $white-300;
  border-bottom: 1px solid $white-300;
  margin-left: -16px;
  margin-right: -16px;

  &__price {
    font-size: 32px;
    line-height: 1;
    font-weight: bold;
    color: $text-color;
    margin-bottom: 5px;
  }
  &__sub {
    font-size: 16px;
    font-weight: normal;
    color: $text-color;
  }
}
</style>

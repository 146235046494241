<template>
  <main-wrapper>
    <template #header>
      <be-header
          :title="userName"
          :back-to-route="purchaseOption === 'appointment' ? 'VenueBasket' : 'VenueVoucherCheckout'"
      >
        <template #subtitle>
          {{$t('b2c_pay')}} {{venueTitle}} <span
            v-if="timer.status"
            :class="timer.countdown < 20 ? 'timer-ending' : ''"
            class="timer"
        > | {{ formattedCountdown || '5 min over' }}</span>
        </template>
      </be-header>
    </template>

    <template #footer>
      <div v-if="paymentMethod" class="be-venue-payment-footer">
        <be-button @click="$bvModal.show(idModalConfirmPaymentMethod)" variant="primary" is-full>{{$t('b2c_confirm_payment')}}</be-button>
      </div>
      <div v-else class="be-venue-payment-footer">
        <be-button @click="createPaymentIndent" variant="primary" is-full>{{$t('b2c_add_card')}}</be-button>
      </div>
    </template>
    <div class="be-venue-payment">
      <be-payment-summary/>
      <be-payment-method
          v-if="!isLoadingPM"
          :is-loading="isCreatingPM"
          @create="createPaymentIndent"
      />
      <div v-else class="text-center pt-5">
        <b-spinner class="be-spinner"/>
      </div>
    </div>

    <be-modal-prompts no-close-on-backdrop :id="idModalSavePaymentCard">
      <be-prompt
          :icon="iconWarning"
          :title="$t('b2c_save_my_credit_card_for_future_bookings')"
      />
      <template #buttons>
        <be-button @click="createPaymentMethod(false)" variant="error">{{$t('b2c_no')}}</be-button>
        <be-button @click="createPaymentMethod(true)" variant="primary">{{$t('b2c_yes')}}</be-button>
      </template>
    </be-modal-prompts>
    <be-modal-prompts :id="idModalConfirmPaymentMethod">
      <be-prompt
          :icon="iconWarning"
          :title="$t('b2c_are_you_sure_you_want_to_make_a_payment')"
      />
      <template #buttons>
        <be-button @click="$bvModal.hide(idModalConfirmPaymentMethod)" variant="error">{{$t('b2c_no')}}</be-button>
        <be-button @click="pay" :is-loading="isLoadingCPM" variant="primary">{{$t('b2c_yes')}}</be-button>
      </template>
    </be-modal-prompts>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper.vue";
import BeHeader from "@/components/BeHeader/index.vue";
import BeButton from "@/components/common/BeButton.vue";
import BePrompt from "@/components/common/BePrompt.vue";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts.vue";
import iconWarning from "@/assets/img/icons/icon-warning.svg";
import BePaymentMethod from "./BePaymentMethod.vue";
import BePaymentSummary from "./BePaymentSummary.vue";
import stripe from "../../../../stripe";
import payment from "../../../../api/payment";

export default {
  name: "PaymentProcessView",
  components: {BeModalPrompts, BePrompt, BeButton, BePaymentSummary, BePaymentMethod, BeHeader, MainWrapper},
  data() {
    return {
      iconWarning,
      isLoadingPM: false,
      isLoadingCPM: false,
      isCreatingPM: false,
      idModalSavePaymentCard: 'Modal-Save-Payment-Card',
      idModalConfirmPaymentMethod: 'Modal-Confirm-payment-method',
      gratuity: {
        list: [
          {
            id: "proposed_1",
            type: "percent",
            value: 18
          },
          {
            id: "proposed_2",
            type: "percent",
            value: 20
          },
          {
            id: "proposed_3",
            type: "percent",
            value: 25
          }
        ],
        selected: {
          status: null,
          id: null,
          type: "percent",
          value: 0
        },
        custom: {
          isPercent: false,
          value: ""
        }
      }
    }
  },
  computed: {
    timer() {
      return this.$store.getters.timer
    },
    formattedCountdown() {
      return this.$moment.utc(this.$moment.duration(this.$store.getters.timer.countdown, "seconds").asMilliseconds()).format("m:ss")
    },
    venueId() {
      return this.$store.getters.venueId
    },
    userName() {
      return this.$store.getters.userProfile
          ? `${this.$store.getters.userProfile.first_name} ${this.$store.getters.userProfile.last_name}`
          : '';
    },
    venueTitle() {
      return this.$store.getters.venue ? this.$store.getters.venue.venueInformation.title : '';
    },
    basket() {
      return this.$store.getters.savedBasket
    },
    totalBasketPrice() {
      let total = 0
      this.$store.getters.savedBasket.forEach(i => {
        if (i.type !== 'hide_price') {
          if(i.package_id) {
            total = total + i.custom_price
          } else {
            total = total + i.price
          }
        }
      })
      return total
    },
    paymentMethod() {
      return this.$store.getters.getPaymentMethod;
    },
    basketToken() {
      return this.$store.getters.savedBasket.length > 0 ? this.$store.getters.savedBasket[0].token : ''
    },
    bookingNote() {
      return this.$store.getters.bookingNote
    },
    paymentVariant() {
      return this.$store.getters.getPaymentVariant
    },
    purchaseOption() {
      return this.$store.getters.purchaseOption
    },
    canVoucherPurchase() {
      return this.$store.getters.canVoucherPurchase
    }
  },
  mounted() {
    if(this.purchaseOption === 'voucher') {
      if(!this.canVoucherPurchase) {
        this.$router.push(`/venues/${this.$route.params.id}`)
      }
    } else {
      if(this.basket.length === 0) {
        this.$router.push(`/venues/${this.$route.params.id}/services`)
        this.$store.commit('startTimer', false)
        return;
      }
    }

    this.checkPaymentMethod();
  },
  methods: {
    loaderPM(value) {
      this.isLoadingPM = value;
    },
    loaderCreatePM(value) {
      this.isCreatingPM = value;
    },
    loaderCPM(value) {
      this.isLoadingCPM = value;
    },
    async checkPaymentMethod() {
      this.loaderPM(true);
      try {
        const {data} = await payment.getPaymentData(this.venueId);
        if(data.payment_method) {
          await this.$store.commit('setPaymentMethod', data.payment_method);
        }

      } catch (e) {
        console.log(e);
      } finally {
        this.loaderPM(false);
      }
    },
    async createPaymentIndent() {
      this.loaderCreatePM(true);
      try {
        const {data} = await payment.getPaymentIndent(this.venueId);

        await this.loaderCreatePM(false);

        await stripe.createPayment({
          stripeAccount: data.account_id,
          clientSecret: data.client_secret,
          merchantDisplayName: this.venueTitle
        }).then(() => this.$bvModal.show(this.idModalSavePaymentCard))
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderCreatePM(false);
      }
    },
    async createPaymentMethod(value) {
      this.$bvModal.hide(this.idModalSavePaymentCard);
      this.loaderPM(true);
      try {
        const {data} = await payment.getPaymentMethod(this.venueId, value);
        if(data) {
          await this.$store.commit('setPaymentMethod', data);
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderPM(false);
      }
    },

    async appointmentPaymentMethod() {
      this.loaderCPM(true);
      try {
        const response = await payment.setPaymentMethod(this.venueId, {
          id: this.paymentMethod.id,
          token: this.basketToken,
          type: this.gratuity.selected.type,
          value: this.gratuity.selected.value,
          payment_variant: this.paymentVariant,
          note: this.bookingNote
        });
        const result = response.data.data

        if(result && result.action && result.action === 'required_action') {
          try {
            await stripe.confirmPayment({
              stripeAccount: result.account_id,
              clientSecret: result.client_secret,
              merchantDisplayName: this.venueTitle,
              customerId: result.customer_id,
              customerName: this.userName,
              ephemeralKeySecret: result.ephemeralKey.secret,
            })

            await payment.checkAppointmentPaymentStatus(this.venueId, {
              id: result.payment_intend_id
            })
            await this.$router.push(`/venues/${this.$route.params.id}/created`);
            await this.$store.commit('startTimer', false);
          } catch (e) {
            console.log(e)
          }
        } else {
          await this.$router.push(`/venues/${this.$route.params.id}/created`);
          await this.$store.commit('startTimer', false);
        }

      } catch (e) {
        console.log(e);
      } finally {
        this.$bvModal.hide(this.idModalConfirmPaymentMethod);
        this.loaderCPM(false);
      }
    },
    async voucherPaymentMethod() {
      this.loaderCPM(true);
      try {
        const response = await this.$store.dispatch('voucherPurchase', this.venueId)

        if(response.action && response.action === 'required_action') {
          try {
            await stripe.confirmPayment({
              stripeAccount: response.account_id,
              clientSecret: response.client_secret,
              merchantDisplayName: this.venueTitle,
              customerId: response.customer_id,
              customerName: this.userName,
              ephemeralKeySecret: response.ephemeralKey.secret,
            })

            await this.$store.dispatch('checkVoucherPurchase', {
              id: this.venueId,
              paymentIntentId: response.payment_intend_id,
            })
            this.$store.commit('resetVoucherForm')
            this.$router.push({name: 'VenueVoucherFinish'})
          } catch (e) {
            console.log(e)
          }
        } else {
          this.$store.commit('resetVoucherForm')
          this.$router.push({name: 'VenueVoucherFinish'})
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderCPM(false);
      }
    },

    pay() {
      if(this.purchaseOption === 'voucher') {
        this.voucherPaymentMethod()
      } else {
        this.appointmentPaymentMethod()
      }
    }
  }
}

</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.timer {
  &-ending {
    animation: flash 1s infinite ease-in-out;

    @keyframes flash {
      from {
        color: rgba($red, 1);
      }
      to {
        color: rgba($red, .4);
      }
    }
  }
}

</style>

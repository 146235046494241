<template>
  <Transition>
    <div
        v-show="isShowFooter"
        class="be-app__footer">
      <footer class="be-footer">
        <div v-if="isShowControl" class="be-footer__controls">
          <div v-if="basket.length > 0 && !isShowBooking || isShowTotal" class="be-footer__controls-selected">
            <div class="d-flex align-items-center justify-content-between">
              <div class="be-footer__controls-selected__info">
                <span class="label">
                  {{ basket.length }} {{ basket.length > 1 ? $t("b2c_services_lowercase") : $t("b2c_service_lowercase") }}
                </span>
                <p class="price">
                  {{ totalBasketPrice | totalBasket(basket, priceData) }}
                </p>
              </div>
              <div class="be-footer__controls-selected__btn">
                <be-button
                    variant="primary"
                    icon-right="icon-be-arrow-line-right"
                    @click="redirectToStaff"
                >
                  {{this.$route.params.employee ? $t('b2c_choose_time') : $t('b2c_choose_staff')}}
                </be-button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="be-footer__toolbar">
          <ul class="be-footer__toolbar-list">
            <li class="item">
              <a
                  :class="$route.path.split('/')[1] === 'venues' ? 'active' : ''"
                  @click.prevent="$route.path.split('/')[1] !== 'venues' ? handlerRedirect('venues') : null"
              >
                <i class="icon-be-list"></i>
              </a>
            </li>
            <li class="item">
              <a
                  :class="$route.path.split('/')[1] === 'appointment' ? 'active' : ''"
                  @click.prevent="$route.path.split('/')[1] !== 'appointment' ? handlerRedirect('appointment') : null"
              >
                <i class="icon-be-appointment"></i>
              </a>
            </li>
<!--            <li class="item">-->
<!--              <a-->
<!--                  :class="$route.path.split('/')[1] === 'notifications' ? 'active' : ''"-->
<!--                  @click.prevent="$route.path.split('/')[1] !== 'notifications' ? handlerRedirect('notifications') : null"-->
<!--              >-->
<!--                <i class="icon-be-notification"></i>-->
<!--              </a>-->
<!--            </li>-->
            <li class="item">
              <a
                  :class="$route.path.split('/')[1] === 'profile' ? 'active' : ''"
                  @click.prevent="$route.path.split('/')[1] !== 'profile' ? handlerRedirect('profile') : null"
              >
                <i class="icon-be-user2"></i>
              </a>
            </li>
          </ul>
        </div>
      </footer>

      <be-modal-prompts
          :id="idUnauthorizedModal"
      >
        <be-prompt-unauthorized-user
            v-if="prompts[selectedRoute]"
            :icon="prompts[selectedRoute].icon"
            :title="prompts[selectedRoute].title"
            :description="prompts[selectedRoute].description"
            @hide-modal="() => $bvModal.hide(idUnauthorizedModal)"
        />
      </be-modal-prompts>
    </div>
  </Transition>
</template>

<script>
import BeModalPrompts from "@/components/BeModals/BeModalPrompts";
import BePromptUnauthorizedUser from "@/components/common/BePromptUnauthorizedUser";

import IconCalendar from "../../assets/img/icons/icon-calendar.svg"
import IconUser from "../../assets/img/icons/icon-user.svg"
import BeButton from "@/components/common/BeButton";
import {priceFormatter} from "@/utils/common";
import venue from "@/api/venue";
import EventBus from "@/utils/event-bus";

export default {
  name: "BeFooter",
  components: {BeButton, BePromptUnauthorizedUser, BeModalPrompts},
  filters: {
    totalBasket(price, array, format) {
      const hidePrice = array.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      const bookable = array.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (hidePrice.length > 0 && bookable.length > 0) {
        return `from ${priceFormatter(price, format)}`;
      } else if (hidePrice.length === 0) {
        return priceFormatter(price, format);
      } else {
        return hidePrice[0].price_formatted;
      }
    },
    priceFormat(amount, formatData) {
      return priceFormatter(amount, formatData)
    },
  },
  data() {
    return {
      idUnauthorizedModal: 'unauthorized-modal',
      isShowFooter: true,
      isShowBooking: false,
      isShowTotal: false,
      isShowControl: false,
      isLoadingBook: false,
      selectedRoute: ''
    }
  },
  watch: {
    $route(to, from) {
      this.routerCheck(to.path, from.path)
    },
    isShowFooter: {
      handler(isShowFooterNew) {
        this.$emit("is-show", isShowFooterNew)
      }
    },
    basket: {
      handler(basket) {
        if((this.$route.path === `/venues/${this.$route.params.id}/services` || this.$route.path === `/venues/${this.$route.params.id}`) && basket.length > 0) {
          this.isShowFooter = true
        } else if(this.$route.path === `/venues/${this.$route.params.id}/view/${this.$route.params.employee}` && basket.length > 0) {
          this.isShowFooter = true
        } else {
          this.isShowFooter = false
        }
      }
    }
  },
  computed: {
    totalBasketPrice() {
      let total = 0
      this.$store.getters.basket.forEach(i => {
        if (i.type !== 'hide_price') {
          if(i.package_id) {
            total = total + i.custom_price
          } else {
            total = total + i.price
          }
        }
      })
      return total
    },
    venueId() {
      return this.$store.getters.venueId
    },
    basket() {
      return this.$store.getters.basket
    },
    priceData() {
      return this.$store.getters.priceData
    },
    installedEmployee() {
      return this.$store.getters.installedEmployee
    },
    prompts() {
      return {
        appointment: {
          icon: IconCalendar,
          title: this.$t('b2c_login_view_your_appointments'),
          description: this.$t('b2c_login_view_your_appointments_description')
        },
        profile: {
          icon: IconUser,
          title: this.$t('b2c_login_view_your_account'),
          description: this.$t('b2c_login_view_your_account_description')
        },
      }
    },

    confirmPhoneIdModal() {
      return this.$store.getters.confirmPhoneIdModal
    },
    isHasCustomerPhone() {
      const profile = this.$store.getters.userProfile

      return profile ? !profile.phone && window.navigator.onLine : false
    },
    savedBasket() {
      let services = this.$store.getters.savedBasket.map(item => {
        return {
          basket_id: item.basket_id,
          employee_id: item.employee_id,
          service_id: item.service_id,
          variant_id: item.variant_id
        }
      })
      return services
    },
    bookingNote() {
      return this.$store.getters.bookingNote
    },
    isPaymentsEnabled() {
      return this.$store.getters.isPaymentsEnabled
    },
    venuePaymentVariant() {
      return this.$store.getters.venuePaymentVariant
    },
    venueDeposit() {
      return this.$store.getters.venueDeposit
    }
  },
  mounted() {
    this.routerCheck(this.$route.path, '/')

    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        if(payload.config.url.split('/')[2] === 'save') {
          self.booking();
        }
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    routerCheck(path) {
      switch (path) {
        case "/":
          this.isShowFooter = false;
          break;
        case "/login":
          this.isShowFooter = false;
          break;
        case "/authorization":
          this.isShowFooter = false;
          break;
        case "/sign-up":
          this.isShowFooter = false;
          break;
        case "/create-account":
          this.isShowFooter = false;
          break;
        case "/check-email":
          this.isShowFooter = false;
          break;
        case "/no-connection":
          this.isShowFooter = false;
          break;

        case `/venues/${this.$route.params.id}`:
          this.isShowFooter = this.basket.length > 0;
          this.isShowControl = true;
          this.isShowTotal = false;
          this.isShowBooking = false;
          break;

        case `/venues/${this.$route.params.id}/voucher-details`:
          this.isShowFooter = false;
          this.isShowControl = false;
          this.isShowTotal = false;
          this.isShowBooking = false;
          break;

        case `/venues/${this.$route.params.id}/voucher-checkout`:
          this.isShowFooter = false;
          this.isShowControl = false;
          this.isShowTotal = false;
          this.isShowBooking = false;
          break;

        case `/venues/${this.$route.params.id}/view/${this.$route.params.employee}`:
          this.isShowControl = true;
          this.isShowTotal = true;
          this.isShowBooking = false;
          this.isShowFooter = this.basket.length > 0;
          break;

        case `/venues/${this.$route.params.id}/services`:
          this.isShowControl = true;
          this.isShowTotal = true;
          this.isShowBooking = false;
          this.isShowFooter = this.basket.length > 0;
          break;

        case `/venues/${this.$route.params.id}/staff`:
          this.isShowFooter = false;
          this.isShowControl = false;
          this.isShowTotal = false;
          this.isShowBooking = false;
          break;

        case `/venues/${this.$route.params.id}/slots`:
          this.isShowFooter = false;
          this.isShowControl = false;
          this.isShowTotal = false;
          this.isShowBooking = false;
          break;

        case `/venues/${this.$route.params.id}/basket`:
          // this.isShowControl = true;
          // this.isShowBooking = true;
          // this.isShowTotal = false;
          // this.isShowFooter = this.basket.length > 0;
          this.isShowFooter = false;
          break;

        case `/venues/${this.$route.params.id}/payment-process`:
          this.isShowFooter = false;
          break;

        case `/venues/${this.$route.params.id}/created`:
          this.isShowFooter = false;
          break;

        default:
          this.isShowFooter = true;
          this.isShowControl = false;
          this.isShowTotal = false;
          this.isShowBooking = false;
      }
    },
    handlerRedirect(path) {
      this.$store.commit('setFollowingPath', `/${path}`);

      if (path === 'appointment' || path === 'profile') {
        if (!this.$store.getters.isAuth) {
          this.selectedRoute = path
          this.$bvModal.show(this.idUnauthorizedModal);
        } else {
          this.$router.push(`/${path}`)
        }
      } else {
        this.$router.push(`/${path}`)
      }
    },
    redirectToStaff() {
      let basket = this.basket

      let flag = basket.every(i => i.employees.length <= 1 && i.employees.length > 0)

      basket = basket.map(item => {
        if (item.employees.length > 1) {
          return item
        }
        return {
          ...item,
          employee_id: item.employees[0].id,
          employee_name: `${item.employees[0].first_name} ${item.employees[0].last_name}`,
          employee_position: item.employees[0].position
        }
      })

      this.$store.commit('updateBasket', basket);

      if(!this.installedEmployee) {
        if (flag) {
          this.$router.push(`/venues/${this.$route.params.id}/slots`)
        } else {
          this.$router.push(`/venues/${this.$route.params.id}/staff`)
        }
      } else {
        this.$router.push(`/venues/${this.$route.params.id}/slots`)
      }
    },
    redirectToPaymentProcess(variant) {
      this.$store.commit('setPaymentVariant', {variant});
      this.$router.push(`/venues/${this.$route.params.id}/payment-process`);
    },

    bookNow() {
      this.$router.push(`/venues/${this.$route.params.id}/services`)
    },

    async booking() {
      if(!this.isHasCustomerPhone) {
        this.isLoadingBook = true
        const data = {
          id: this.venueId,
          note: this.bookingNote,
          services: [...this.savedBasket]
        }
        try {
          await venue.saveAppointment(data)

          this.isLoadingBook = false
          await this.$router.push(`/venues/${this.$route.params.id}/created`)
          await this.$store.commit('startTimer', false)
        } catch (e) {
          this.isLoadingBook = false
          console.log(e.response)
        }
      } else {
        this.$bvModal.show(this.confirmPhoneIdModal)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";
@import "../../assets/scss/global/mixins";
@import "../../assets/scss/global/utils";

.v-enter-active,
.v-leave-active {
  transition: .2s;
}

.v-enter-active,
.v-enter-to {
  transform: translateY(0);
  transition: .2s;
}

.v-leave-active,
.v-leave-to {
  transition: .2s;
  transform: translateY(100%);
}

.be-footer {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  &__toolbar {
    &-list {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      justify-content: space-around;
      align-items: center;

      .item {
        a {
          cursor: pointer;
          display: inline-block;
          text-decoration: none;
          color: $text-color;
          font-size: 28px;

          &.router-link-active, &.active {
            color: $primary-color;
          }
        }
      }
    }
  }

  &__controls {
    &-selected {
      &__info {
        .label {
          display: inline-block;
          font-size: 14px;
          font-weight: normal;
          color: $text-color;
        }

        .price {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: $text-color;
        }
      }
    }
  }
}

</style>

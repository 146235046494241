import {PaymentSheetEventsEnum, Stripe} from "@capacitor-community/stripe";

const PUBLISH_KEY = process.env.VUE_APP_STRIPE_API_PUBLISH_KEY

export default {
  createPayment(payload) {
    const {stripeAccount, clientSecret, merchantDisplayName} = payload

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await Stripe.initialize({
          publishableKey: PUBLISH_KEY,
          stripeAccount,
        });
        await Stripe.createPaymentSheet({
          setupIntentClientSecret: clientSecret,
          merchantDisplayName
        });

        const result = await Stripe.presentPaymentSheet();

        if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
          resolve('success')
        } else {
          reject('canceled')
        }
      } catch (e) {
        reject(e)
      }
    })
  },
  confirmPayment(payload) {
    const {stripeAccount, clientSecret, merchantDisplayName, customerId, ephemeralKeySecret, customerName} = payload

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await Stripe.initialize({
          publishableKey: PUBLISH_KEY,
          stripeAccount
        });

        await Stripe.createPaymentSheet({
          merchantDisplayName: merchantDisplayName,
          customerId: customerId,
          customerEphemeralKeySecret: ephemeralKeySecret,
          paymentIntentClientSecret: clientSecret,
          defaultBillingDetails: {
            name: customerName,
          }
        });

        const result = await Stripe.presentPaymentSheet();

        if(result.paymentResult === PaymentSheetEventsEnum.Completed) {
          resolve('success')
        } else {
          reject('canceled')
        }
      } catch (e) {
        reject(e)
      }
    })
  }
}

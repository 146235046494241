export const VOUCHER_FORM = {
  id: 0,
  type: 'else',
  payment_id: 0,

  recipient_name: '',
  recipient_email: '',
  sender_name: '',
  message: '',
  send: 'instantly',
  date_send: '',
}

<template>
  <div class="vi-voucher-card">
    <div class="vi-voucher-card__main">
      <span class="price">
        {{data.amount | priceFormat(priceData)}}
      </span>
      <span v-if="isDiscount" class="label-price">{{$t("b2c_voucher_you_pay", {amount: data.price_formatted})}} {{data.price_formatted}}</span>
    </div>
    <div class="vi-voucher-card__tools">
      <be-button @click="$emit('select', data.id)" variant="primary">{{$t('b2c_buy')}}</be-button>
    </div>
  </div>
</template>

<script>
import BeButton from "../common/BeButton.vue";
import {priceFormatter} from "../../utils/common";

export default {
  name: "BeCardListVoucher",
  components: {BeButton},
  props: {
    isDiscount: Boolean,
    data: Object,
  },
  filters: {
    priceFormat(price, format) {
      return priceFormatter(price, format);
    }
  },
  computed: {
    priceData() {
      return this.$store.getters.priceData
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.vi-voucher-card {
  padding: 13px 15px;
  border-bottom: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__main {
    .price {
      display: inline-block;
      white-space: nowrap;
      margin-bottom: 5px;
      font-weight: 600;
    }
    .label-price {
      display: inline-block;
      padding: 6px 8px;
      border-radius: 6px;
      background: $white-300;
      font-size: 12px;
    }
  }
  &__tools {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .btn-price {
      padding: 7px 12px;
      text-transform: capitalize;
    }
  }
}

</style>

<template>
  <button
      class="be-btn"
      ref="mainButton"
      v-ripple
      :type="type"
      :disabled="isLoading || disabled"
      @click="handlerClick"
      :class="`
      ${flat ? variantsFlat[variant] : isOutline ? variantsOutline[variant] : variants[variant]}
      ${disabled ? 'be-btn-disabled' : ''}
      ${isRubble ? 'be-rubble' : ''}
      ${isLoading ? 'be-btn-loading be-btn-disable' : ''}
      ${isSelected ? 'be-btn-selected' : ''}
      ${round ? 'be-btn-round' : ''}
      ${square ? 'be-btn-square' : ''}
      ${badge ? 'be-btn-badge' : ''}
      ${isFull ? 'be-btn-full' : ''}`"
  >
      <b-spinner v-if="isLoading" small class="be-btn__spinner"/>
      <span>
        <slot></slot>
        <i v-if="iconRight" :class="iconRight" class="be-btn__icon-right"/>
        <i v-if="isSelected" class="icon-selected icon-be-check"/>
      </span>
  </button>
</template>

<script>
export default {
  name: "BeButton",
  props: {
    variant: {
      type: String,
      required: false,
      default: 'default',
      validator(value) {
        return ['default', 'primary', 'secondary', 'error', 'google', 'apple'].indexOf(value) > -1
      }
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    isOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    isFull: {
      type: Boolean,
      required: false,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean
    },
    iconRight: {
      type: String
    },
    round: {
      type: Boolean
    },
    flat: {
      type: Boolean
    },
    badge: {
      type: Boolean
    },
    square: {
      type: Boolean,
    }
  },
  data() {
    return {
      isRubble: false,
      variants: {
        'default': 'be-btn-default',
        'primary': 'be-btn-primary',
        'secondary': 'be-btn-secondary',
        'error': 'be-btn-error',

        'google': 'be-btn-google',
        'apple': 'be-btn-apple',
      },
      variantsOutline: {
        'default': 'be-btn-default-outline',
        'primary': 'be-btn-primary-outline',
        'secondary': 'be-btn-secondary-outline',
        'error': 'be-btn-error-outline',

        'google': 'be-btn-google',
        'apple': 'be-btn-apple',
      },
      variantsFlat: {
        'default': 'be-btn-default-flat',
        'primary': 'be-btn-primary-flat',
        'secondary': 'be-btn-secondary-flat',
        'error': 'be-btn-error-flat',

        'google': 'be-btn-google',
        'apple': 'be-btn-apple',
      },
    }
  },
  methods: {
    handlerClick() {
       this.$emit('click')
    }
  }
}
</script>

import Vue from 'vue';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import App from './App.vue';
import './registerServiceWorker';
import './plugins/bootstrap';
import "./assets/scss/main.scss";
import router from './router';
import store from './store';

import { i18n } from "./i18n";

import axios from "axios";

import VueAxios from "vue-axios";

import VueTelInput from 'vue-tel-input';

import 'vue-tel-input/dist/vue-tel-input.css';

import './plugins/vue2-touch-events';
import './plugins/vue-ripple';
import './plugins/moment';

Vue.use(VueTelInput);

Vue.use(VueAxios, axios);

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
}

if(process.env.VUE_APP_SENTRY_URI) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_URI,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "b2c.belliata.dev", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
